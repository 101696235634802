import { useState, useEffect, useRef } from "react";

import { useLocation, useParams } from "react-router-dom";

import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../db";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";
import Spinner from "../components/Spinner";
import NotFound from "../components/NotFound";
import LineComponent from "../components/Line";
import CopyButton from "../components/CopyButton";
import CopyInnerButton from "../components/CopyInnerButton";
import DevOnly from "../components/DevOnly";

const LineJSONButton = () => {
  return (
    <button
      type="button"
      className="btn btn-outline-danger"
      data-bs-toggle="collapse"
      data-bs-target="#json"
      aria-expanded="false"
      aria-controls="json"
    >
      Toggle JSON
    </button>
  );
};

const LineJSONBody = (props) => {
  const { line } = props;

  if (!line) {
    return null;
  }

  return (
    <div className="card card-body collapse fade" id="json">
      <pre>{JSON.stringify(line, undefined, 2)}</pre>
    </div>
  );
};

const LineSelectButton = () => {
  return (
    <button
      type="button"
      className="btn btn-outline-danger"
      data-bs-toggle="collapse"
      data-bs-target="#select"
      aria-expanded="true"
      aria-controls="select"
    >
      Toggle Select
    </button>
  );
};

const LineSelectBody = (props) => {
  const [selection, setSelection] = useState();
  const { line } = props;

  const { text } = line;

  useEffect(() => {
    const onSelectionChange = async () => {
      const s = document.getSelection();
      if (s && s.rangeCount > 0) {
        const range = s.getRangeAt(0);
        if (range) {
          const substring = text.substring(range.startOffset, range.endOffset);
          const rendered =
            "- " +
            JSON.stringify(
              [range.startOffset, range.endOffset, substring],
              undefined,
              1
            ).replace(/\n/g, "");
          try {
            if (navigator.clipboard) {
              await navigator.clipboard.writeText(rendered);
            }
          } catch (e) {
            console.error(e);
          }
          setSelection(rendered);
        }
      }
    };
    document.addEventListener("selectionchange", onSelectionChange);
    return () => {
      document.removeEventListener("selectionchange", onSelectionChange);
    };
  }, [text]);

  if (!line) {
    return null;
  }

  return (
    <div className="card card-body collapse fade show" id="select">
      <pre>{text}</pre>
      <pre>{selection}</pre>
    </div>
  );
};

const LineShareButton = (props) => {
  const { line, breadcrumbs } = props;

  const { pathname } = useLocation();

  if (!line) {
    return null;
  }

  if (!navigator.share) {
    return null;
  }

  const { time, speaker, text } = line;

  const shareTitle = breadcrumbs?.filter((i) => i).join(" - ");
  const shareText = [time, speaker, text].filter(Boolean).join(" ");

  const shareData = {
    title: shareTitle,
    text: shareText,
    url: new URL(pathname, window.location.origin),
  };

  const onClick = async (e) => {
    try {
      if (navigator.share) {
        await navigator.share(shareData);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={onClick}
      >
        Share
      </button>
    </>
  );
};

const Line = (props) => {
  const innerText = useRef(null);
  const { options } = props;
  const { missionSlug, transcriptSlug, pageSlug, lineSlug } = useParams();
  const { line, loading } = useLiveQuery(
    async () => {
      try {
        return await db.transaction("r", db.tables, async () => {
          const line = await db.lines.get({
            slug: lineSlug,
            missionSlug: missionSlug || 0,
            transcriptSlug: transcriptSlug || 0,
            pageSlug: pageSlug || 0,
          });
          return { line };
        });
      } catch (e) {
        return { error: e };
      }
    },
    [missionSlug, transcriptSlug, pageSlug, lineSlug],
    { loading: true }
  );

  const breadcrumbs = [
    line?.missionSlug,
    line?.transcriptSlug,
    line?.pageSlug,
    line?.slug,
  ];

  if (loading) {
    return <Spinner />;
  }

  if (!line) {
    return <NotFound />;
  }

  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>Line {line?.slug}</h1>
      <div className="list-group">
        <div ref={innerText} className="list-group-item">
          <LineComponent line={line} options={options} />
        </div>
      </div>
      <DevOnly>
        <div className="btn-group" role="group" aria-label="Line actions">
          <LineSelectButton />
          <LineJSONButton />
          <LineShareButton line={line} breadcrumbs={breadcrumbs} />
          <CopyButton element={line?.time} className="btn btn-outline-danger">
            Copy Time
          </CopyButton>
          <CopyButton
            element={line?.speaker}
            className="btn btn-outline-danger"
          >
            Copy Speaker
          </CopyButton>
          <CopyButton element={line?.text} className="btn btn-outline-danger">
            Copy Text
          </CopyButton>
          <CopyInnerButton
            element={line}
            refValue={innerText}
            querySelector=".time-field"
            className="btn btn-outline-danger"
          >
            Copy Inner Time
          </CopyInnerButton>
          <CopyInnerButton
            element={line}
            refValue={innerText}
            querySelector=".speaker-field"
            className="btn btn-outline-danger"
          >
            Copy Inner Speaker
          </CopyInnerButton>
          <CopyInnerButton
            element={line}
            refValue={innerText}
            querySelector=".text-field"
            className="btn btn-outline-danger"
          >
            Copy Inner Text
          </CopyInnerButton>
          <CopyInnerButton
            element={line}
            refValue={innerText}
            querySelectorAll=".time-field, .speaker-field, .text-field"
            className="btn btn-outline-danger"
          >
            Copy Inner
          </CopyInnerButton>
        </div>
        <LineSelectBody line={line} />
        <LineJSONBody line={line} />
      </DevOnly>
    </>
  );
};

export default Line;
