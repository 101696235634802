import { useState } from "react";

import { useParams } from "react-router-dom";

import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../db";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";
import PageComponent from "../components/Page";
import Spinner from "../components/Spinner";
import NotFound from "../components/NotFound";

const AllPages = (props) => {
  const incrementBy = 25;
  const { options } = props;
  const { missionSlug, transcriptSlug } = useParams();
  const [count, setCount] = useState(incrementBy);
  const { transcript, loading } = useLiveQuery(
    async () => {
      try {
        return await db.transaction("r", db.tables, async () => {
          const transcript = await db.transcripts.get({
            missionSlug: missionSlug || 0,
            slug: transcriptSlug || 0,
          });

          if (transcript) {
            [transcript.pages] = [
              await db.pages
                .where({
                  missionSlug: transcript?.missionSlug || 0,
                  transcriptSlug: transcript?.slug || 0,
                })
                .limit(count)
                .toArray(),
            ];
          }
          return { transcript };
        });
      } catch (e) {
        return { error: e };
      }
    },
    [missionSlug, transcriptSlug, count],
    { loading: true }
  );

  const breadcrumbs = [transcript?.missionSlug, transcript?.slug, "All Pages"];

  if (loading) {
    return <Spinner />;
  }

  if (!transcript) {
    return <NotFound />;
  }

  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>{transcript?.name}</h1>
      {transcript?.pages?.map((page, idx) => (
        <PageComponent key={idx} page={page} options={options} />
      ))}
      {transcript?.pages?.length === count && (
        <div
          ref={(el) =>
            el &&
            new IntersectionObserver(
              (entries) => {
                const target = entries[0];
                if (target.isIntersecting) {
                  setCount((prev) => prev + incrementBy);
                }
              },
              {
                root: null,
                rootMargin: "20px",
                threshold: 0,
              }
            ).observe(el)
          }
        >
          &nbsp;
        </div>
      )}
    </>
  );
};

export default AllPages;
