import { useReducer } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useLocation,
} from "react-router-dom";

import Nav from "./components/Nav";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Reset from "./pages/Reset";
import Missions from "./pages/Missions";
import Options from "./pages/Options";
import Transcripts from "./pages/Mission";
import Transcript from "./pages/Transcript";
import Pages from "./pages/Pages";
import AllPages from "./pages/AllPages";
import Lines from "./pages/Page";
import Line from "./pages/Line";

const Layout = () => {
  const { pathname } = useLocation();
  return (
    <>
      <ScrollToTop pathname={pathname} />
      <div className="container-fluid">
        <Nav />
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
};

const App = () => {
  const [options, dispatch] = useReducer(
    (state, action) => {
      const { name, option, value } = action.payload;

      switch (action.type) {
        case "preset":
          return value
            ? {
                ...state,
                preset: name,
                parameters: { ...value?.parameters },
              }
            : { ...state, preset: name };
        case "option":
          switch (name) {
            case "subscripts":
            case "underlines":
            case "quotes":
            case "alignments":
            case "continuations":
            case "newlines":
            case "separators":
            case "hyphenations":
            case "abbreviations":
            case "corrections":
            case "highlights":
            case "times":
            case "gets":
            case "speakers":
            case "spacecraft":
            case "people":
            case "flags":
            case "garbles":
            case "shadows":
              return {
                ...state,
                preset: "",
                parameters: {
                  ...state.parameters,
                  [name]: {
                    ...state.parameters[name],
                    [option]: value,
                  },
                },
              };
            default:
              throw new Error();
          }
        default:
          throw new Error();
      }
    },
    {
      preset: "",
      parameters: {
        subscripts: { enabled: true, format: "tag" },
        underlines: { enabled: true, format: "tag" },
        quotes: { enabled: true, format: "tag" },
        alignments: { enabled: true },
        continuations: { enabled: true },
        garbles: { enabled: true },
        newlines: { enabled: false },
        separators: { enabled: false },
        hyphenations: { enabled: true },
        shadows: { enabled: true },
        abbreviations: {
          enabled: true,
          display: "tooltip",
        },
        corrections: {
          enabled: true,
          display: "diff",
        },
        highlights: { enabled: true },
        times: {
          enabled: true,
          display: "tooltip",
          format: "time - duration",
        },
        gets: {
          enabled: true,
          display: "tooltip",
          format: "time",
        },
        speakers: {
          enabled: true,
          display: "tooltip",
          format: "fullName",
        },
        spacecraft: {
          enabled: true,
          display: "tooltip",
        },
        people: {
          enabled: true,
          display: "tooltip",
          format: "fullName",
        },
        flags: {
          enabled: true,
        },
      },
    }
  );

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="missions" element={<Missions />} />
          <Route path="about" element={<About />} />
          <Route path="reset" element={<Reset />} />
          <Route
            path="options"
            element={<Options options={options} dispatch={dispatch} />}
          />
          <Route path=":missionSlug">
            <Route index element={<Transcripts />} />
            <Route path=":transcriptSlug">
              <Route index element={<Transcript options={options} />} />
              <Route path="pages" element={<Pages />} />
              <Route path="all" element={<AllPages options={options} />} />
              <Route path=":pageSlug">
                <Route index element={<Lines options={options} />} />
                <Route path=":lineSlug" element={<Line options={options} />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
