import LineText from "./LineText";

const Line = (props) => {
  const { line, options } = props;

  const { parameters } = options;

  if (!line) {
    return null;
  }
  const { render, goss } = line;
  const { time, speaker, text } = render;
  const { shadowTime, shadowSpeaker } = render;

  const styles = {
    whiteSpace: parameters.newlines.enabled ? "pre-wrap" : undefined,
  };

  const separator = parameters.separators.enabled ? ": " : null;

  const shadows = parameters.shadows.enabled;

  const classes = (() => {
    const baseClasses = [
      "flex-column",
      "flex-md-row",
      "d-flex",
      "flex-wrap",
      "gap-1",
      "ps-2",
    ];
    switch (goss) {
      case "start":
        return ["border-start", "border-top", ...baseClasses];
      case "end":
        return ["border-start", "border-bottom", ...baseClasses];
      case "middle":
        return ["border-start", ...baseClasses];
      default:
        return baseClasses;
    }
  })().join(" ");

  return (
    <div style={styles} className={classes}>
      {time && (
        <div className="time-field">
          <LineText {...props} render={time} />
        </div>
      )}
      {shadows && !time && shadowTime && (
        <div className="time-field fw-light">
          <LineText {...props} render={shadowTime} />
        </div>
      )}
      {speaker && (
        <div className="speaker-field">
          <LineText {...props} render={speaker} />
          {separator}
        </div>
      )}
      {shadows && !speaker && shadowSpeaker && (
        <div className="speaker-field fw-light">
          <LineText {...props} render={shadowSpeaker} />
        </div>
      )}
      <div className="text-field d-flex flex-fill flex-grow-1">
        <LineText {...props} render={text} />
      </div>
    </div>
  );
};

export default Line;
