import { NavLink } from "react-router-dom";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";
import AprilFools from "../components/AprilFools";
import Alert from "../components/Alert";
import React from "react";

const Home = () => {
  const breadcrumbs = [];
  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>Home</h1>
      <Alert>
        Welcome our amazing Patrons! This website isn't ready for everyone yet,
        so please keep this secret!
      </Alert>
      <AprilFools />
      <p>
        This website contains hand-transcribed versions of a (growing) set of
        Apollo Program transcripts with customizable annotations for the casual
        reader and the space history aficionado alike.
      </p>
      <p>
        Select a <NavLink to="/missions">Mission</NavLink> to begin.
      </p>
    </>
  );
};

export default Home;
