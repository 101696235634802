import { NavLink, useResolvedPath } from "react-router-dom";

const Breadcrumb = (props) => {
  const { path, name, link } = props;

  if (!name) {
    return null;
  }

  if (!link) {
    return (
      <li className="breadcrumb-item active" aria-current="page">
        {name}
      </li>
    );
  }

  return (
    <li className="breadcrumb-item">
      <NavLink to={path}>{name}</NavLink>
    </li>
  );
};

const Breadcrumbs = (props) => {
  const { breadcrumbs } = props;

  const { pathname } = useResolvedPath("");

  const pathAtIndex = (idx) => {
    return (
      pathname
        .split("/")
        .splice(0, idx + 2)
        .join("/") || "/"
    );
  };

  return (
    <nav aira-label="breadcrumb">
      <ol className="breadcrumb">
        <Breadcrumb path={"/"} name={"Home"} link={"/"} />
        {breadcrumbs?.map((i, idx) => (
          <Breadcrumb
            key={idx}
            path={pathAtIndex(idx)}
            name={i}
            link={idx !== breadcrumbs.length - 1}
          />
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
