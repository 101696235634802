import { NavLink } from "react-router-dom";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";

const About = () => {
  const breadcrumbs = ["About"];
  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>About</h1>

      <p>
        Have you ever considered exploring the Apollo Program mission
        transcripts, but don't know where to start?
      </p>

      <p>
        This website contains hand-transcribed versions of a (growing) set of
        Apollo Program transcripts with customizable annotations for the casual
        reader and the space history aficionado alike.
      </p>

      <p>
        The transcripts here are all created directly from NASA sources and our
        data-driven version (will be) available as public domain. The
        annotations are stored separately from the original text to provide a
        clean copy, independent of this website's requirements. Annotations can
        be configured to personal preference and are marked clearly from the
        original text.
      </p>

      <p>
        When we searched the internet for this resource we were unable to find
        it, so we hope this website will fufill the needs for ourselves and for
        the future. By providing a copy of these documents in electronic form,
        we hope to open up access to the Apollo Program transcripts to a wider
        audience.
      </p>

      <p>
        Note: Transcribing, annotating, and reviewing these historical documents{" "}
        <strong>takes time</strong> and would not have been possible without the
        support of{" "}
        <a
          rel="noopener noreferrer"
          referrerPolicy="no-referrer"
          target="_blank"
          href="https://www.patreon.com/apollo_50th"
        >
          our Patrons
        </a>
        .
      </p>

      <p>
        Select a <NavLink to="/missions">Mission</NavLink> to begin.
      </p>
    </>
  );
};

export default About;
