const CopyButton = (props) => {
  const { className, element, children } = props;

  if (!element) {
    return null;
  }

  if (!navigator.clipboard) {
    return null;
  }

  const onClick = async (e) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(element);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <button type="button" className={className} onClick={onClick}>
        {children}
      </button>
    </>
  );
};

export default CopyButton;
