const Title = (props) => {
  const { breadcrumbs } = props;

  const filteredBreadcrumbs = breadcrumbs.filter((i) => i);

  const title = ["Apollo Houston", ...filteredBreadcrumbs].join(" - ");

  document.title = title;

  return null;
};

export default Title;
