import { NavLink, useParams, generatePath } from "react-router-dom";

import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../db";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";
import Spinner from "../components/Spinner";
import NotFound from "../components/NotFound";

const Mission = (props) => {
  const { missionSlug } = useParams();
  const { mission, transcripts, loading } = useLiveQuery(
    async () => {
      try {
        return await db.transaction("r", db.tables, async () => {
          const mission = await db.missions.get({ slug: missionSlug });
          const transcripts = await db.transcripts
            .where({ missionSlug: missionSlug || 0 })
            .toArray();
          return { mission, transcripts };
        });
      } catch (e) {
        return { error: e };
      }
    },
    [missionSlug],
    { loading: true }
  );

  const breadcrumbs = [mission?.slug];

  if (loading) {
    return <Spinner />;
  }

  if (!mission) {
    return <NotFound />;
  }

  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>{mission?.name}</h1>
      <div className="list-group mb-3">
        {transcripts?.map((transcript, idx) => (
          <NavLink
            key={idx}
            to={generatePath("/:mission/:transcript", {
              mission: transcript?.missionSlug,
              transcript: transcript?.slug,
            })}
            className="list-group-item list-group-item-action"
          >
            {transcript.name}
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default Mission;
