import { NavLink, useParams } from "react-router-dom";

import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../db";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";
import NotFound from "../components/NotFound";
import Page from "../components/Page";
import Pagination from "../components/Pagination";
import Spinner from "../components/Spinner";

const Transcript = (props) => {
  const { missionSlug, transcriptSlug } = useParams();
  const { options } = props;

  const { transcript, page, loading } = useLiveQuery(
    async () => {
      try {
        return await db.transaction("r", db.tables, async () => {
          const transcript = await db.transcripts.get({
            missionSlug: missionSlug || 0,
            slug: transcriptSlug || 0,
          });

          const page = await db.pages
            .where({
              missionSlug: transcript?.missionSlug || 0,
              transcriptSlug: transcript?.slug || 0,
            })
            .first();

          return { transcript, page };
        });
      } catch (e) {
        return { error: e };
      }
    },
    [missionSlug, transcriptSlug],
    { loading: true }
  );

  const breadcrumbs = [transcript?.missionSlug, transcript?.slug];

  if (loading) {
    return <Spinner />;
  }

  if (!transcript) {
    return <NotFound />;
  }

  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>{transcript?.name}</h1>
      <div className="list-group mb-3">
        <NavLink
          to="all"
          className="list-group-item list-group-item-action"
          end
        >
          All Pages
        </NavLink>
        <NavLink
          to="pages"
          className="list-group-item list-group-item-action"
          end
        >
          Pages List
        </NavLink>
      </div>
      <Pagination page={page} />
      <Page page={page} options={options} />
      <Pagination page={page} />
    </>
  );
};

export default Transcript;
