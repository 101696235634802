const CopyInnerButton = (props) => {
  const {
    className,
    element,
    refValue,
    querySelector,
    querySelectorAll,
    children,
  } = props;

  if (!element) {
    return null;
  }

  if (!navigator.clipboard) {
    return null;
  }

  const onClick = async (e) => {
    try {
      if (navigator.clipboard) {
        if (querySelectorAll) {
          const value = [
            ...refValue.current?.querySelectorAll(querySelectorAll),
          ]
            .map((i) => i.innerText)
            .join(" ");
          await navigator.clipboard.writeText(value);
        } else {
          const value = querySelector
            ? refValue.current?.querySelector(querySelector)?.innerText
            : refValue.current?.innerText;
          await navigator.clipboard.writeText(value);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <button type="button" className={className} onClick={onClick}>
        {children}
      </button>
    </>
  );
};

export default CopyInnerButton;
