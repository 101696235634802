import { NavLink, generatePath } from "react-router-dom";

const PaginationLink = (props) => {
  const { page, slug, aria, children } = props;

  if (!page) {
    return (
      <li className="page-item disabled">
        <span className="page-link" {...aria}>
          {children}
        </span>
      </li>
    );
  }

  if (!slug) {
    return (
      <li className="page-item disabled">
        <span className="page-link" {...aria}>
          {children}
        </span>
      </li>
    );
  }

  return (
    <li className="page-item">
      <NavLink
        className="page-link"
        to={generatePath("/:mission/:transcript/:page", {
          mission: page?.missionSlug,
          transcript: page?.transcriptSlug,
          page: encodeURIComponent(slug),
        })}
        {...aria}
      >
        {children}
      </NavLink>
    </li>
  );
};

const Pagination = (props) => {
  const { page } = props;

  if (!page) {
    return null;
  }

  const { pagination } = page;

  const { first, prev, next, last } = pagination;

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <PaginationLink
          page={page}
          slug={first}
          aria={{ "aria-label": "First" }}
        >
          <span aria-hidden="true">&#x21E4;</span>
        </PaginationLink>
        <PaginationLink
          page={page}
          slug={prev}
          aria={{ "aria-label": "Previous" }}
        >
          <span aria-hidden="true">&#x2190;</span>
        </PaginationLink>
        <li className="page-item active" aria-current="page">
          <NavLink className="page-link" to="">
            {page?.slug || <>&nbsp;</>}
          </NavLink>
        </li>
        <PaginationLink page={page} slug={next} aria={{ "aria-label": "Next" }}>
          <span aria-hidden="true">&#x2192;</span>
        </PaginationLink>
        <PaginationLink page={page} slug={last} aria={{ "aria-label": "Last" }}>
          <span aria-hidden="true">&#x21E5;</span>
        </PaginationLink>
      </ul>
    </nav>
  );
};

export default Pagination;
