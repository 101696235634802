import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../db";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";
import Line from "../components/Line";

const presetOptions = {
  formatting: {
    alignments: { title: "Alignments" },
    continuations: { title: "Continuations" },
    garbles: { title: "Garbles" },
    hyphenations: { title: "Hyphenations" },
    newlines: { title: "New Lines" },
    separators: { title: "Separators" },
    quotes: {
      title: "Quotes",
      formatSettings: ["tag", "unicode"],
    },
    shadows: { title: "Shadows" },
    subscripts: {
      title: "Subscripts",
      formatSettings: ["tag", "unicode", "underline"],
    },
    underlines: { title: "Underlines", formatSettings: ["tag", "underline"] },
  },
  annotations: {
    abbreviations: {
      title: "Abbreviations",
      displaySettings: ["tooltip", "append", "replace"],
    },
    corrections: {
      title: "Corrections",
      displaySettings: ["diff", "replace"],
    },
    flags: { title: "Flags" },
    gets: {
      title: "Ground Elapsed Times",
      displaySettings: ["tooltip", "append", "replace"],
      formatSettings: [
        "time",
        "time (ISO8601)",
        "duration",
        "duration (ISO8601)",
        "T+00:00:00:00",
        "T+000:00:00",
      ],
    },
    highlights: { title: "Highlights" },
    people: {
      title: "People",
      displaySettings: ["tooltip", "append", "replace"],
      formatSettings: ["name", "fullName"],
    },
    spacecraft: {
      title: "Spacecraft",
      displaySettings: ["tooltip", "append", "replace", "brackets"],
    },
    speakers: {
      title: "Speakers",
      displaySettings: ["tooltip", "append", "replace"],
      formatSettings: ["name", "fullName"],
    },
    times: {
      title: "Times",
      displaySettings: ["tooltip", "append"],
      formatSettings: [
        "time",
        "time (ISO8601)",
        "duration",
        "duration (ISO8601)",
        "time - duration",
        "time (ISO8601) - duration (ISO8601)",
      ],
    },
  },
};

const Presets = (props) => {
  const { options, dispatch } = props;

  const { preset } = options;

  const { presets, loading } = useLiveQuery(
    async () => {
      try {
        return await db.transaction("r", db.presets, async () => {
          const presets = await db.presets.toArray();
          return { presets };
        });
      } catch (e) {
        return { error: e };
      }
    },
    undefined,
    { loading: true }
  );

  const onChange = (e) => {
    dispatch({
      type: "preset",
      payload: {
        name: e.target.value,
        value: presets.find((i) => i.name === e.target.value),
      },
    });
  };

  return (
    <div className="form-floating">
      <select
        id="preset"
        className="form-select"
        value={preset}
        onChange={onChange}
        aira-label="Preset"
        disabled={loading}
      >
        {loading ? (
          <option>Loading ...</option>
        ) : (
          <>
            <option value="">Custom</option>
            {presets?.map((preset, idx) => (
              <option key={idx} value={preset.name}>
                {preset.name}
              </option>
            ))}
          </>
        )}
      </select>
      <label htmlFor="preset">Preset</label>
    </div>
  );
};

const Examples = (props) => {
  const { options } = props;

  const exampleLines = [
    {
      slug: "1",
      idx: 0,
      time: null,
      speaker: null,
      text: "End",
      render: {
        text: [
          {
            a: "alignments",
            c: [
              {
                t: "End",
              },
            ],
            v: "right",
          },
        ],
      },
    },
    {
      slug: "2",
      idx: 1,
      time: "01 02 03 04",
      speaker: "TEST1-TEST1",
      text: "Start ABBR mistake flag highlight person time hyphe-\nnation subscript H2O underline End.",
      render: {
        time: [
          {
            a: "replacement",
            c: [
              {
                c: [
                  {
                    a: "gets",
                    v: {
                      time: "1970-01-02T02:03:04.000Z",
                      duration: {
                        duration: "P1DT2H3M4S",
                      },
                    },
                    t: "01 02 03 04",
                  },
                ],
              },
            ],
            v: [
              {
                c: [
                  {
                    a: "gets",
                    v: {
                      time: "1970-01-05T03:02:01.000Z",
                      duration: {
                        duration: "P4DT3H2M1S",
                      },
                    },
                    t: "04 03 02 01",
                  },
                ],
              },
            ],
          },
        ],
        speaker: [
          {
            a: "replacement",
            c: [
              {
                c: [
                  {
                    a: "speakers",
                    v: {
                      name: "Speaker Name 1",
                      fullName: "Test Speaker Name 1",
                    },
                    t: "TEST1",
                  },
                  {
                    a: "separators",
                    v: "-",
                    t: "-",
                  },
                  {
                    a: "spacecraft",
                    v: {
                      name: "Spacecraft Name 1",
                    },
                    t: "TEST1",
                  },
                ],
              },
            ],
            v: [
              {
                c: [
                  {
                    a: "speakers",
                    v: {
                      name: "Speaker Name 2",
                      fullName: "Test Speaker Name 2",
                    },
                    t: "TEST2",
                  },
                  {
                    a: "separators",
                    v: "-",
                    t: "-",
                  },
                  {
                    a: "spacecraft",
                    v: {
                      name: "Spacecraft Name 2",
                    },
                    t: "TEST2",
                  },
                ],
              },
            ],
          },
        ],
        text: [
          {
            a: "alignments",
            c: [
              {
                a: "quotes",
                c: [
                  {
                    a: "continuations",
                    v: "end",
                    t: "",
                  },
                  {
                    c: [
                      {
                        t: "Start ",
                      },
                      {
                        a: "abbreviations",
                        v: "Definition",
                        t: "ABBR",
                      },
                      {
                        t: " ",
                      },
                      {
                        a: "corrections",
                        v: "correction",
                        t: "mistake",
                      },
                      {
                        t: " ",
                      },
                      {
                        a: "flags",
                        t: "flag",
                      },
                      {
                        t: " ",
                      },
                      {
                        a: "highlights",
                        v: "comment",
                        t: "highlight",
                      },
                      {
                        t: " ",
                      },
                      {
                        a: "people",
                        v: {
                          name: "Person Name 1",
                          fullName: "Test Person Name 1",
                        },
                        t: "person",
                      },
                      {
                        t: " ",
                      },
                      {
                        a: "times",
                        v: {
                          time: {
                            time: "1970-01-05T03:02:01.000Z",
                            corrected: "1970-01-05T03:02:01.000Z",
                          },
                          duration: {
                            duration: "P3DT58M57S",
                          },
                        },
                        t: "time",
                      },
                      {
                        t: " ",
                      },
                      {
                        a: "hyphenations",
                        v: "hyphenation",
                        t: "hyphe-\nnation",
                      },
                      {
                        t: " ",
                      },
                      {
                        a: "subscripts",
                        t: "subscript",
                      },
                      {
                        t: " H",
                      },
                      {
                        a: "subscripts",
                        v: {
                          unicode: "₂",
                        },
                        t: "2",
                      },
                      {
                        t: "O ",
                      },
                      {
                        a: "underlines",
                        v: "underline",
                        t: "underline",
                      },
                      {
                        t: " End.",
                      },
                    ],
                  },
                  {
                    a: "continuations",
                    v: "start",
                    t: "",
                  },
                ],
              },
            ],
            v: "left",
          },
        ],
      },
    },
  ];

  return (
    <div className="list-group">
      {exampleLines.map((line, idx) => (
        <div key={idx} className="list-group-item">
          <Line line={line} options={options}></Line>
        </div>
      ))}
    </div>
  );
};

const Enabled = (props) => {
  const { name, section, value, dispatch } = props;

  const settingsValue = presetOptions[section][name];
  const { title } = settingsValue;

  const htmlFor = `enable${name}`;

  const onChange = (e) => {
    dispatch({
      type: "option",
      payload: {
        name: name,
        option: "enabled",
        value: e.target.checked,
      },
    });
  };

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id={htmlFor}
        checked={value}
        onChange={onChange}
        disabled={value === null}
      />
      <label htmlFor={htmlFor} className="form-check-label">
        Enable {title}
      </label>
    </div>
  );
};

const FormatSetting = (props) => {
  const { name, section, value, dispatch } = props;

  const settingsValue = presetOptions[section][name];
  const { formatSettings } = settingsValue;

  const htmlFor = `format${name}`;

  const onChange = (e) => {
    dispatch({
      type: "option",
      payload: {
        name: name,
        option: "format",
        value: e.target.value,
      },
    });
  };

  if (!value) {
    return null;
  }

  return (
    <div className="form-floating">
      <select
        className="form-select me-1"
        type="select"
        id={htmlFor}
        value={value}
        onChange={onChange}
        disabled={value === null}
        aria-label="Format as:"
      >
        {formatSettings?.map((setting, idx) => (
          <option key={idx} value={setting}>
            {setting}
          </option>
        ))}
      </select>
      <label htmlFor={htmlFor}>Format as:</label>
    </div>
  );
};

const DisplaySetting = (props) => {
  const { name, section, value, dispatch } = props;

  const settingsValue = presetOptions[section][name];
  const { displaySettings } = settingsValue;

  const htmlFor = `display${name}`;

  const onChange = (e) => {
    dispatch({
      type: "option",
      payload: {
        name: name,
        option: "display",
        value: e.target.value,
      },
    });
  };

  if (!value) {
    return null;
  }

  return (
    <div className="form-floating">
      <select
        className="form-select me-1"
        type="select"
        id={htmlFor}
        value={value}
        onChange={onChange}
        disabled={value === null}
        aria-label="Display as:"
      >
        {displaySettings?.map((setting, idx) => (
          <option key={idx} value={setting}>
            {setting}
          </option>
        ))}
      </select>
      <label htmlFor={htmlFor}>Display as:</label>
    </div>
  );
};

const Option = (props) => {
  const { name, section, options, dispatch } = props;

  const value = options.parameters[name];

  const { enabled, display, format } = value;

  return (
    <div className="list-group-item">
      <div className="row row-cols-1 row-cols-sm-3">
        <div className="col">
          <Enabled
            name={name}
            section={section}
            value={enabled}
            dispatch={dispatch}
          />
        </div>
        {enabled && (
          <div className="col">
            <FormatSetting
              name={name}
              section={section}
              value={format}
              dispatch={dispatch}
            />
          </div>
        )}
        {enabled && (
          <div className="col">
            <DisplaySetting
              name={name}
              section={section}
              value={display}
              dispatch={dispatch}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const Options = (props) => {
  const { annotations, formatting } = presetOptions;

  const breadcrumbs = ["Options"];

  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>Options</h1>
      <Examples {...props} />
      <hr />
      <Presets {...props} />
      <nav>
        <div className="nav nav-tabs nav-fill" role="tablist">
          <button
            className="nav-link active"
            id="formatting-tab"
            type="button"
            data-bs-toggle="tab"
            data-bs-target="#formatting"
            role="tab"
            aria-controls="formatting"
            aria-selected="true"
          >
            Formatting
          </button>
          <button
            className="nav-link"
            id="annotations-tab"
            type="button"
            data-bs-toggle="tab"
            data-bs-target="#annotations"
            role="tab"
            aria-controls="annotations"
            aria-selected="false"
          >
            Annotations
          </button>
        </div>
      </nav>
      <div className="tab-content">
        <div
          className="tab-pane fade show active"
          id="formatting"
          role="tabpanel"
          aria-labelledby="formatting-tab"
        >
          <div className="list-group mb-3">
            {Object.keys(formatting).map((option, idx) => (
              <Option key={idx} section="formatting" name={option} {...props} />
            ))}
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="annotations"
          role="tabpanel"
          aria-labelledby="annotations-tab"
        >
          <div className="list-group mb-3">
            {Object.keys(annotations).map((option, idx) => (
              <Option
                key={idx}
                section="annotations"
                name={option}
                {...props}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Options;
