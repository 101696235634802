import Dexie from "dexie";

export const db = new Dexie("ApolloHouston");

db.version(1).stores({
  missions: "++id, slug",
  transcripts: "++id, slug, [missionSlug+slug]",
  pages:
    "++id, [missionSlug+transcriptSlug], [missionSlug+transcriptSlug+slug]",
  lines:
    "++id, [slug+missionSlug+transcriptSlug+pageSlug], [missionSlug+transcriptSlug+pageSlug]",
  presets: "++id, name",
});

const fetchJSON = async (url) => {
  return await fetch(`${url}.json`, {
    headers: {
      Accept: "application/json",
    },
  })
    .then((data) => {
      if (data.ok) {
        return data.json();
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

const loadAjax = async () => {
  const missionsData = await fetchJSON("/data/missions");
  const { missions } = missionsData;
  const transcripts = await Promise.all(
    missions.map(async (mission) => {
      return await Promise.all(
        mission.transcripts.map(
          async (i) => await fetchJSON(`/data/${mission.path}/${i.path}`)
        )
      );
    })
  );
  return { missions, transcripts };
};

const loadMissions = (missions, transcriptsData) => {
  missions.forEach((mission, missionIdx) => {
    db.missions.put({
      name: mission.name,
      slug: mission.slug,
      idx: missionIdx,
    });
    mission.transcripts.forEach(async (transcript, idx) => {
      const transcriptData = transcriptsData[missionIdx][idx];
      const { name, slug } = transcript;
      db.transcripts.put({
        name,
        slug,
        idx,
        missionSlug: mission.slug,
      });
      transcriptData?.pages.forEach((page, i) => {
        const { name, slug, idx } = page;
        db.pages.put({
          name,
          slug,
          idx,
          pagination: {
            first: i !== 0 ? transcriptData.pages.at(0)?.slug : undefined,
            prev: i > 0 ? transcriptData.pages.at(i - 1)?.slug : undefined,
            current: slug,
            next:
              i < transcriptData.pages.length - 1
                ? transcriptData.pages.at(i + 1)?.slug
                : undefined,
            last:
              i !== transcriptData.pages.length - 1
                ? transcriptData.pages.at(-1)?.slug
                : undefined,
          },
          missionSlug: mission.slug,
          transcriptSlug: transcript.slug,
          lines: page.lines.map((line) => {
            return {
              ...line,
              missionSlug: mission.slug,
              transcriptSlug: transcript.slug,
              pageSlug: slug,
            };
          }),
        });
        page.lines.forEach((line) => {
          db.lines.put({
            ...line,
            missionSlug: mission.slug,
            transcriptSlug: transcript.slug,
            pageSlug: page.slug,
          });
        });
      });
    });
  });
};

const loadPresets = async () => {
  const presets = [
    {
      name: "Defaults",
      parameters: {
        subscripts: { enabled: true, format: "tag" },
        underlines: { enabled: true, format: "tag" },
        quotes: { enabled: true, format: "tag" },
        alignments: { enabled: true },
        continuations: { enabled: true },
        garbles: { enabled: true },
        newlines: { enabled: false },
        separators: { enabled: false },
        hyphenations: { enabled: true },
        shadows: { enabled: true },
        abbreviations: {
          enabled: true,
          display: "tooltip",
        },
        corrections: {
          enabled: true,
          display: "diff",
        },
        highlights: { enabled: true },
        times: {
          enabled: true,
          display: "tooltip",
          format: "time - duration",
        },
        gets: {
          enabled: true,
          display: "tooltip",
          format: "time",
        },
        speakers: {
          enabled: true,
          display: "tooltip",
          format: "fullName",
        },
        spacecraft: {
          enabled: true,
          display: "tooltip",
        },
        people: {
          enabled: true,
          display: "tooltip",
          format: "fullName",
        },
        flags: {
          enabled: true,
        },
      },
    },
    {
      name: "Paper",
      parameters: {
        subscripts: { enabled: true, format: "tag" },
        underlines: { enabled: true, format: "tag" },
        quotes: { enabled: false, format: "tag" },
        alignments: { enabled: true },
        continuations: { enabled: false },
        garbles: { enabled: false },
        newlines: { enabled: true },
        separators: { enabled: false },
        hyphenations: { enabled: false },
        shadows: { enabled: false },
        abbreviations: {
          enabled: false,
          display: "tooltip",
        },
        corrections: {
          enabled: false,
          display: "diff",
        },
        highlights: { enabled: false },
        times: {
          enabled: false,
          display: "tooltip",
          format: "time",
        },
        gets: {
          enabled: false,
          display: "tooltip",
          format: "time",
        },
        speakers: {
          enabled: false,
          display: "tooltip",
          format: "name",
        },
        spacecraft: {
          enabled: false,
          display: "tooltip",
        },
        people: {
          enabled: false,
          display: "tooltip",
          format: "name",
        },
        flags: {
          enabled: false,
        },
      },
    },
    {
      name: "Verbose",
      parameters: {
        subscripts: { enabled: true, format: "tag" },
        underlines: { enabled: true, format: "tag" },
        quotes: { enabled: true, format: "tag" },
        alignments: { enabled: true },
        continuations: { enabled: true },
        garbles: { enabled: true },
        newlines: { enabled: false },
        separators: { enabled: false },
        hyphenations: { enabled: true },
        shadows: { enabled: true },
        abbreviations: {
          enabled: true,
          display: "append",
        },
        corrections: {
          enabled: true,
          display: "diff",
        },
        highlights: { enabled: true },
        times: {
          enabled: true,
          display: "append",
          format: "time - duration",
        },
        gets: {
          enabled: true,
          display: "append",
          format: "time",
        },
        speakers: {
          enabled: true,
          display: "append",
          format: "fullName",
        },
        spacecraft: {
          enabled: true,
          display: "append",
        },
        people: {
          enabled: true,
          display: "append",
          format: "fullName",
        },
        flags: {
          enabled: true,
        },
      },
    },
  ];
  db.presets.bulkAdd(presets);
};

db.on("ready", async () => {
  const { missions, transcripts } = await loadAjax();
  return Promise.all([db.tables.map((table) => table.clear())])
    .then(() => {
      db.transaction("rw", db.tables, () => {
        return Promise.all([
          loadMissions(missions, transcripts),
          loadPresets(),
        ]);
      });
    })
    .catch((e) => console.error(e));
});
