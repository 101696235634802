const DevOnly = (props) => {
  const { children } = props;

  if (process.env.NODE_ENV === "production") {
    return null;
  }

  return children;
};

export default DevOnly;
