import { NavLink, generatePath } from "react-router-dom";

import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../db";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";
import Spinner from "../components/Spinner";

const Missions = () => {
  const { missions, loading } = useLiveQuery(
    async () => {
      try {
        return await db.transaction("r", db.tables, async () => {
          const missions = await db.missions.toArray();
          return { missions };
        });
      } catch (e) {
        return { error: e };
      }
    },
    undefined,
    { loading: true }
  );

  const breadcrumbs = ["Missions"];

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>Missions</h1>
      <div className="list-group mb-3">
        {missions?.map((i, idx) => (
          <NavLink
            key={idx}
            to={generatePath("/:mission", { mission: i?.slug })}
            className="list-group-item list-group-item-action"
          >
            {i.name}
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default Missions;
