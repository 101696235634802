import { useParams } from "react-router-dom";

import { useLiveQuery } from "dexie-react-hooks";

import { db } from "../db";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";
import NotFound from "../components/NotFound";
import PageComponent from "../components/Page";
import Pagination from "../components/Pagination";
import Spinner from "../components/Spinner";

const Page = (props) => {
  const { options } = props;
  const { missionSlug, transcriptSlug, pageSlug } = useParams();

  const { page, loading } = useLiveQuery(
    async () => {
      try {
        return await db.transaction("r", db.tables, async () => {
          const page = await db.pages
            .where({
              missionSlug: missionSlug || 0,
              transcriptSlug: transcriptSlug || 0,
              slug: pageSlug,
            })
            .first();

          return { page };
        });
      } catch (e) {
        return { loading: false, error: e };
      }
    },
    [missionSlug, transcriptSlug, pageSlug],
    { loading: true }
  );

  const breadcrumbs = [page?.missionSlug, page?.transcriptSlug, page?.slug];

  if (loading) {
    return <Spinner />;
  }

  if (!page) {
    return <NotFound />;
  }

  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>{page?.name}</h1>
      <Pagination page={page} />
      <PageComponent page={page} options={options} />
      <Pagination page={page} />
    </>
  );
};

export default Page;
