import { useNavigate } from "react-router-dom";

import Title from "../components/Title";
import Breadcrumbs from "../components/Breadcrumbs";

import { db } from "../db";

const ResetButton = (props) => {
  const navigate = useNavigate();

  const onClick = async () => {
    if (window.confirm("Reset data and reload?")) {
      await db.delete();
      await db.open();
      navigate(0);
    }
  };

  return (
    <button className="btn btn-danger" type="button" onClick={onClick}>
      Reset Data
    </button>
  );
};

const Reset = () => {
  const breadcrumbs = ["Reset"];
  return (
    <>
      <Title breadcrumbs={breadcrumbs} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h1>Reset</h1>

      <ResetButton />
    </>
  );
};

export default Reset;
