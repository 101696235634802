import { useLayoutEffect } from "react";

const ScrollToTop = (props) => {
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [props]);

  return null;
};

export default ScrollToTop;
