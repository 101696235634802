import { useRef } from "react";

import { Link, generatePath } from "react-router-dom";

import LineText from "./Line";

const PageLink = (props) => {
  const { page, line } = props;

  if (!page) {
    return null;
  }

  return (
    <Link
      to={generatePath("/:mission/:transcript/:page/:line", {
        mission: line?.missionSlug,
        transcript: line?.transcriptSlug,
        page: encodeURIComponent(line?.pageSlug),
        line: line?.slug,
      })}
    >
      {"\u2026"}
    </Link>
  );
};

const PageLine = (props) => {
  const { line, options } = props;
  const innerText = useRef(null);

  return (
    <div className="list-group-item d-flex gap-1">
      <div ref={innerText} className="flex-fill">
        <LineText line={line} options={options} />
      </div>
      <PageLink {...props} line={line} />
    </div>
  );
};

const Page = (props) => {
  const { page } = props;

  if (!page) {
    return null;
  }

  const { lines } = page;

  return (
    <>
      <div className="list-group mb-3">
        {lines?.map((line, idx) => (
          <PageLine key={idx} line={line} {...props} />
        ))}
      </div>
    </>
  );
};

export default Page;
