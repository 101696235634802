import { useEffect, useState } from "react";

const Spinner = () => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), 100);

    return () => clearTimeout(timer);
  });

  if (!showSpinner) {
    return null;
  }

  return (
    <div className="d-flex flex-column justify-content-center align-items-center m-5">
      <div
        className="spinner-border text-primary"
        style={{ width: "5rem", height: "5rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
