import { useState } from "react";

const Alert = (props) => {
  const { children } = props;

  const [visible, setVisible] = useState(true);

  const onClick = (e) => {
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <div
      className="alert alert-success alert-dismissible fade show"
      role="alert"
      data-nosnippet="true"
    >
      {children}
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
        onClick={onClick}
      ></button>
    </div>
  );
};

export default Alert;
