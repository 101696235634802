import { useState, useEffect } from "react";

import Alert from "./Alert";

import { DateTime } from "luxon";

const AprilFools = () => {
  const [date, setDate] = useState(DateTime.local());

  useEffect(() => {
    const delay = 1000 * 60 * 5;
    const timer = setTimeout(() => {
      setDate(DateTime.local());
    }, delay);
    return () => clearTimeout(timer);
  }, []);

  if (date?.get("month") === 4 && date?.get("day") === 1) {
    const formattedDate = date.toLocaleString({
      month: "long",
      day: "numeric",
    });
    return (
      <Alert>
        Coming Soon: Transcripts for Apollo 18, 19, and 20 on {formattedDate}!
      </Alert>
    );
  }
  return null;
};

export default AprilFools;
