import React from "react";

import { Tooltip } from "bootstrap";
import { DateTime, Duration } from "luxon";

const Display = (props) => {
  const { value, parameters, children } = props;

  const { enabled, display } = parameters;

  if (!enabled) {
    return children;
  }

  switch (display) {
    case "tooltip":
      return (
        <span
          style={{
            textDecorationLine: "underline",
            textDecorationStyle: "dotted",
            textDecorationColor: "black",
            textUnderlinePosition: "under",
          }}
          data-bs-toggle="tooltip"
          title={value}
          ref={(el) => el && new Tooltip(el)}
        >
          {children}
        </span>
      );
    case "append":
      return (
        <>
          {children}{" "}
          <ins
            style={{
              textDecorationLine: "underline",
              textDecorationStyle: "solid",
              textDecorationColor: "green",
              textUnderlinePosition: "under",
            }}
          >
            ({value})
          </ins>
        </>
      );
    case "replace":
    case "brackets":
      return (
        <ins
          style={{
            textDecorationLine: "underline",
            textDecorationStyle: "solid",
            textDecorationColor: "green",
            textUnderlinePosition: "under",
          }}
        >
          {value}
        </ins>
      );
    default:
      return children;
  }
};

const Abbr = (props) => {
  const { value, parameters, children } = props;

  const { enabled } = parameters.abbreviations;

  if (!enabled) {
    return children;
  }

  const formattedValue = (() => {
    return value;
  })();

  return (
    <Display parameters={parameters.abbreviations} value={formattedValue}>
      {children}
    </Display>
  );
};

const GroundElapsedTime = (props) => {
  const { value, parameters, children } = props;

  const { enabled, format } = parameters.gets;

  if (!enabled) {
    return children;
  }

  const { time, duration } = value;

  const formatDurationValue = (d) => {
    const formatInner = (value) => {
      switch (format) {
        case "duration":
          return value.toHuman({
            unitDisplay: "short",
          });
        case "duration (ISO8601)":
          return value.toISO();
        case "T+00:00:00:00":
          return value.toMillis() >= 0
            ? value.toFormat("T+dd:hh:mm:ss")
            : value.toFormat("T-dd:hh:mm:ss");
        case "T+000:00:00":
          return value.toMillis() >= 0
            ? value.toFormat("T+hhh:mm:ss")
            : value.toFormat("T-hhh:mm:ss");
        default:
          return "";
      }
    };
    const { duration, shifted } = d;
    const durationValue = duration ? Duration.fromISO(duration) : undefined;
    const shiftedValue = shifted ? Duration.fromISO(shifted) : undefined;
    if (!durationValue) {
      return "";
    }

    if (shiftedValue && shiftedValue.toMillis() !== durationValue.toMillis()) {
      return `${formatInner(durationValue)} → ${formatInner(shiftedValue)}`;
    }
    return formatInner(durationValue);
  };

  const formattedValue = (() => {
    const t = DateTime.fromISO(time, { setZone: true });
    switch (format) {
      case "time":
        return t.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
      case "time (ISO8601)":
        return t.toISO();
      case "time (ISO8601) PLUS 50":
        return t.plus({ years: 50 }).toISO();
      case "duration":
      case "duration (ISO8601)":
      case "T+00:00:00:00":
      case "T+000:00:00":
        return formatDurationValue(duration);
      default:
        return "";
    }
  })();

  return (
    <Display parameters={parameters.gets} value={formattedValue}>
      {children}
    </Display>
  );
};

const Speaker = (props) => {
  const { value, parameters, children } = props;

  const { enabled, format } = parameters.speakers;

  if (!enabled) {
    return children;
  }

  const { name, fullName } = value;

  const fallback = fullName || name;

  const formattedValue = (() => {
    switch (format) {
      case "name":
        return name || fallback;
      case "fullName":
        return fullName || fallback;
      default:
        return "";
    }
  })();

  return (
    <Display parameters={parameters.speakers} value={formattedValue}>
      {children}
    </Display>
  );
};

const Spacecraft = (props) => {
  const { value, parameters, children } = props;

  const { enabled, display } = parameters.spacecraft;

  if (!enabled) {
    return children;
  }

  const { name } = value;

  const formattedValue = (() => {
    switch (display) {
      case "brackets":
        return `(${name})`;
      case "tooltip":
      case "append":
      case "replace":
        return name;
      default:
        return "";
    }
  })();

  return (
    <Display parameters={parameters.spacecraft} value={formattedValue}>
      {children}
    </Display>
  );
};

const Separator = (props) => {
  const { parameters, children } = props;

  const { enabled, display } = parameters.spacecraft;

  if (!enabled) {
    return children;
  }

  const formattedValue = (() => {
    switch (display) {
      case "brackets":
        return " ";
      case "tooltip":
        return children;
      case "append":
        return children;
      case "replace":
        return children;
      default:
        return "";
    }
  })();

  return formattedValue;
};

const Person = (props) => {
  const { value, parameters, children } = props;

  const { enabled, format } = parameters.people;

  if (!enabled) {
    return children;
  }

  const { name, fullName } = value;

  const fallback = fullName || name;

  const formattedValue = (() => {
    switch (format) {
      case "name":
        return name || fallback;
      case "fullName":
        return fullName || fallback;
      default:
        return "";
    }
  })();

  return (
    <Display parameters={parameters.people} value={formattedValue}>
      {children}
    </Display>
  );
};

const Time = (props) => {
  const { value, parameters, children } = props;

  const { enabled, format } = parameters.times;
  const { enabled: correctionsEnabled, display: correctionsDisplay } =
    parameters.corrections;

  if (!enabled) {
    return children;
  }

  const { time, duration } = value;

  const formatDurationValue = (d) => {
    const formatInner = (value) => {
      switch (format) {
        case "time - duration":
        case "duration":
          return value.toMillis() >= 0
            ? `in ${value.toHuman({
                unitDisplay: "short",
              })}`
            : `${value.negate().toHuman({
                unitDisplay: "short",
              })} ago`;
        case "time (ISO8601) - duration (ISO8601)":
        case "duration (ISO8601)":
          return value.toISO();
        default:
          return "";
      }
    };

    const { duration, shifted } = d;
    const durationValue = duration ? Duration.fromISO(duration) : undefined;
    const shiftedValue = shifted ? Duration.fromISO(shifted) : undefined;

    if (!durationValue) {
      return "";
    }

    if (shiftedValue && shiftedValue.toMillis() !== durationValue.toMillis()) {
      return `${formatInner(durationValue)} → ${formatInner(shiftedValue)}`;
    }
    return formatInner(durationValue);
  };

  const formatTimeValue = (t) => {
    const formatInner = (value) => {
      switch (format) {
        case "time":
        case "time - duration":
          return value.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
        case "time (ISO8601)":
        case "time (ISO8601) - duration (ISO8601)":
          return value.toISO();
        default:
          return "";
      }
    };

    const { time, shifted } = t;
    const timeValue = duration
      ? DateTime.fromISO(time, { setZone: true })
      : undefined;
    const shiftedValue = shifted
      ? DateTime.fromISO(shifted, { setZone: true })
      : undefined;

    if (!timeValue) {
      return "";
    }

    if (shiftedValue && shiftedValue.toMillis() !== timeValue.toMillis()) {
      return `${formatInner(timeValue)} → ${formatInner(shiftedValue)}`;
    }
    return formatInner(timeValue);
  };

  const formattedTime = (() => {
    if (!time) {
      return "";
    }
    switch (format) {
      case "time":
      case "time - duration":
      case "time (ISO8601)":
      case "time (ISO8601) - duration (ISO8601)":
        return formatTimeValue(time);
      default:
        return "";
    }
  })();

  const formattedDuration = (() => {
    const uncorrected = {
      duration: duration?.duration,
      shifted: duration?.shifted,
    };
    const corrected = duration?.corrected
      ? {
          duration: duration?.corrected,
          shifted: duration?.correctedShifted,
        }
      : undefined;

    if (corrected && correctionsEnabled) {
      switch (correctionsDisplay) {
        case "diff":
          return `${formatDurationValue(uncorrected)}/${formatDurationValue(
            corrected
          )}`;
        case "replace":
          return formatDurationValue(corrected);
        default:
          return "";
      }
    }
    return formatDurationValue(uncorrected);
  })();

  const formattedValue = (() => {
    switch (format) {
      case "time":
      case "time (ISO8601)":
        return formattedTime;
      case "duration":
      case "duration (ISO8601)":
        return formattedDuration;
      case "time - duration":
      case "time (ISO8601) - duration (ISO8601)":
        return `${formattedTime} - ${formattedDuration}`;
      default:
        return "";
    }
  })();

  return (
    <Display parameters={parameters.times} value={formattedValue}>
      {children}
    </Display>
  );
};

const Correction = (props) => {
  const { value, parameters, children } = props;

  const { enabled, display } = parameters.corrections;

  if (!enabled) {
    return children;
  }

  const formattedValue = (() => {
    return value;
  })();

  switch (display) {
    case "diff":
      return (
        <span>
          <del
            style={{
              color: "red",
              textDecorationLine: "line-through",
              textDecorationStyle: "solid",
              textDecorationColor: "red",
            }}
          >
            {children}
          </del>
          &nbsp;
          <ins
            style={{
              color: "green",
              textDecorationLine: "underline",
              textDecorationStyle: "solid",
              textDecorationColor: "green",
              textUnderlinePosition: "under",
            }}
          >
            {formattedValue}
          </ins>
        </span>
      );
    case "replace":
      return (
        <ins
          style={{
            textDecorationLine: "underline",
            textDecorationStyle: "solid",
            textDecorationColor: "green",
            textUnderlinePosition: "under",
          }}
        >
          {formattedValue}
        </ins>
      );
    default:
      return children;
  }
};

const Highlight = (props) => {
  const { value, parameters, children } = props;

  const { enabled } = parameters.highlights;

  if (!enabled) {
    return children;
  }

  const formattedValue = (() => {
    return value;
  })();

  return (
    <mark
      data-bs-toggle="tooltip"
      title={formattedValue}
      style={{
        textDecorationLine: "underline",
        textDecorationStyle: "dotted",
        textDecorationColor: "black",
        textUnderlinePosition: "under",
      }}
      ref={(el) => el && new Tooltip(el)}
    >
      {children}
    </mark>
  );
};

const Flag = (props) => {
  const { value, parameters, children } = props;

  const { enabled } = parameters.flags;

  if (!enabled) {
    return children;
  }

  const formattedValue = (() => {
    return value;
  })();

  return (
    <mark
      style={{
        backgroundColor: "red",
        color: "white",
      }}
      data-bs-toggle="tooltip"
      title={formattedValue}
      ref={(el) => el && new Tooltip(el)}
    >
      {children}
    </mark>
  );
};

const Hyphenation = (props) => {
  const { value, parameters, children } = props;

  const { enabled } = parameters.hyphenations;

  const { enabled: enabledNewlines } = parameters.newlines;

  if (!enabled) {
    return children;
  }

  const formattedValue = (() => {
    switch (enabledNewlines) {
      case true:
        return `${value}\n`;
      case false:
        return value;
      default:
        return "";
    }
  })();

  if (formattedValue) {
    return (
      <ins
        style={{
          textDecorationLine: "underline",
          textDecorationStyle: "solid",
          textDecorationColor: "green",
          textUnderlinePosition: "under",
        }}
      >
        {formattedValue}
      </ins>
    );
  }
  return children;
};

const Alignment = (props) => {
  const { value, parameters, children } = props;

  const { enabled } = parameters.alignments;

  if (!enabled) {
    return (
      <p className="d-inline-flex flex-fill text-start mb-0">{children}</p>
    );
  }

  switch (value) {
    case "left":
      return (
        <p className="d-inline-block flex-fill text-start mb-0">{children}</p>
      );
    case "center":
      return (
        <p className="d-inline-block flex-fill text-center mb-0">{children}</p>
      );
    case "right":
      return (
        <p className="d-inline-block flex-fill text-end mb-0">{children}</p>
      );
    default:
      return children;
  }
};

const Garble = (props) => {
  const { value, parameters, children } = props;

  const { enabled } = parameters.garbles;

  if (!enabled) {
    return children;
  }

  const formattedValue = (() => {
    switch (value) {
      case "...":
        return "[garble]";
      case "***":
        return "[clipping]";
      case "- -":
        return "[interruption]";
      default:
        return "[garble]";
    }
  })();

  if (formattedValue) {
    return (
      <ins
        style={{
          textDecorationLine: "underline",
          textDecorationStyle: "solid",
          textDecorationColor: "green",
          textUnderlinePosition: "under",
        }}
      >
        {formattedValue}
      </ins>
    );
  }
  return children;
};

const Continuation = (props) => {
  const { value, parameters, children } = props;

  const { enabled } = parameters.continuations;

  if (!enabled) {
    return <>{children}</>;
  }

  switch (value) {
    case "start":
      return (
        <>
          {" "}
          <ins
            style={{
              textDecorationLine: "underline",
              textDecorationStyle: "solid",
              textDecorationColor: "green",
              textUnderlinePosition: "under",
            }}
          >
            {"\u2026"}
          </ins>
        </>
      );
    case "end":
      return (
        <>
          <ins
            style={{
              textDecorationLine: "underline",
              textDecorationStyle: "solid",
              textDecorationColor: "green",
              textUnderlinePosition: "under",
            }}
          >
            {"\u2026"}
          </ins>{" "}
        </>
      );
    default:
      return <>{children}</>;
  }
};

const Subscript = (props) => {
  const { value, parameters, children } = props;

  const { enabled, format } = parameters.subscripts;

  if (!enabled) {
    return children;
  }

  const { unicode } = value || {};

  const formattedValue = (() => {
    switch (format) {
      case "tag":
        return <sub>{children}</sub>;
      case "unicode":
        return unicode ? unicode : <sub>{children}</sub>;
      case "underline":
        return unicode ? unicode : `_${children}`;
      default:
        return "";
    }
  })();

  return formattedValue;
};

const Underline = (props) => {
  const { parameters, children } = props;

  const { enabled, format } = parameters.underlines;

  if (!enabled) {
    return children;
  }

  const formattedValue = (() => {
    switch (format) {
      case "tag":
        return <u>{children}</u>;
      case "underline":
        return `_${children}_`;
      default:
        return "";
    }
  })();

  return formattedValue;
};

const Quote = (props) => {
  const { parameters, children } = props;

  const { enabled, format } = parameters.quotes;

  const formattedValue = (() => {
    switch (format) {
      case "tag":
        return <q>{children}</q>;
      case "unicode":
        return (
          <>
            {"\u201C"}
            {children}
            {"\u201D"}
          </>
        );
      default:
        return "";
    }
  })();

  if (!enabled) {
    return children;
  }

  return formattedValue;
};

const LineText = (props) => {
  const { line, options, render } = props;

  const { parameters } = options;

  if (!line) {
    return null;
  }

  const format = (value) => {
    const formatElement = (i) => {
      const { t: text, a: action, v: value, c: children } = i;
      const inner = children ? format(children) : text;

      switch (action) {
        case "replacement":
          const renderedValue = format(value);
          return (
            <Correction parameters={parameters} value={renderedValue}>
              {inner}
            </Correction>
          );
        case "abbreviations":
          return (
            <Abbr parameters={parameters} value={value}>
              {inner}
            </Abbr>
          );
        case "corrections":
          return (
            <Correction parameters={parameters} value={value}>
              {inner}
            </Correction>
          );
        case "highlights":
          return (
            <Highlight parameters={parameters} value={value}>
              {inner}
            </Highlight>
          );
        case "flags":
          return (
            <Flag parameters={parameters} value={value}>
              {inner}
            </Flag>
          );
        case "times":
          return (
            <Time parameters={parameters} value={value}>
              {inner}
            </Time>
          );
        case "gets":
          return (
            <GroundElapsedTime parameters={parameters} value={value}>
              {inner}
            </GroundElapsedTime>
          );
        case "garbles":
          return (
            <Garble parameters={parameters} value={value}>
              {inner}
            </Garble>
          );
        case "speakers":
          return (
            <Speaker parameters={parameters} value={value}>
              {inner}
            </Speaker>
          );
        case "spacecraft":
          return (
            <Spacecraft parameters={parameters} value={value}>
              {inner}
            </Spacecraft>
          );
        case "separators":
          return (
            <Separator parameters={parameters} value={value}>
              {inner}
            </Separator>
          );
        case "people":
          return (
            <Person parameters={parameters} value={value}>
              {inner}
            </Person>
          );
        case "hyphenations":
          return (
            <Hyphenation parameters={parameters} value={value}>
              {inner}
            </Hyphenation>
          );
        case "alignments":
          return (
            <Alignment parameters={parameters} value={value}>
              {inner}
            </Alignment>
          );
        case "continuations":
          return <Continuation parameters={parameters} value={value} />;
        case "subscripts":
          return (
            <Subscript parameters={parameters} value={value}>
              {inner}
            </Subscript>
          );
        case "underlines":
          return <Underline parameters={parameters}>{inner}</Underline>;
        case "quotes":
          return <Quote parameters={parameters}>{inner}</Quote>;
        default:
          return inner;
      }
    };

    return value?.map((i, idx) => (
      <React.Fragment key={idx}>{formatElement(i)}</React.Fragment>
    ));
  };

  return format(render);
};

export default LineText;
